<template>
  <div class="cdb-rdb">
    <v-row class="flex-column content" align="start">
      <v-form ref="form" lazy-validation class="formulario">
        <v-row class="flex-column line" align="start">
          <v-col class="d-flex flex-column align-start py-0">
            <span class="text-filelds">Nome e Sobrenome</span>
          </v-col>
          <v-col class="column">
            <v-text-field
              v-model="user.name"
              placeholder="Roberto Silva"
              :rules="[(v) => !!v || 'Nome requerido']"
              color="secondary"
              required
              dense
              outlined
              rounded
              class="input"
            />
          </v-col>
        </v-row>

        <v-row class="flex-column line" align="start">
          <v-col class="d-flex flex-column align-start py-0">
            <span class="text-filelds">E-mail</span>
          </v-col>
          <v-col class="column">
            <v-text-field
              v-model="user.email"
              placeholder="curtis.weave@example.com"
              :rules="[(v) => !!v || 'E-mail requerido']"
              color="secondary"
              required
              dense
              outlined
              rounded
              class="input"
            />
          </v-col>
        </v-row>

        <v-row class="flex-column line" align="start">
          <v-col class="d-flex flex-column align-start py-0">
            <span class="text-filelds">Telefone</span>
          </v-col>
          <v-col class="column">
            <v-text-field
              v-model="user.phoneNumber"
              placeholder="(22) 5555-9090"
              v-mask="['(##)####-####', '(##)#####-####']"
              :rules="[(v) => !!v || 'Telefone requerido']"
              color="secondary"
              required
              dense
              outlined
              rounded
              class="input"
            />
          </v-col>
        </v-row>
        <v-row class="flex-column line" align="start">
          <v-col class="d-flex flex-column align-left py-0">
            <span class="text-filelds">Tipo de Plano</span>
          </v-col>
          <v-col class="column">
            <v-select
              v-model="user.tipoPlano"
              :items="items"
              item-text="name"
              :menu-props="{ bottom: true, offsetY: true }"
              placeholder="Selecione"
              color="secondary"
              required
              outlined
              rounded
              dense
              height="0"
              class="select"
              append-icon=""
            ></v-select>
          </v-col>
        </v-row>
      </v-form>
    </v-row>
  </div>
</template>

<script>
import ApiService from "../../../../services/ApiService";
import EventBus from "../../../Bus/bus";

export default {
  props: {
    btnRegisterClicado: { type: Boolean },
  },
  data() {
    return {
      items: ["Grátis"],
      user: {
        email: "",
        name: "",
        phoneNumber: "",
        advisorUserId: "",
        tipoPlano: "Grátis",
      },
      apiService: new ApiService(),
    };
  },
  components: {},
  methods: {
    async submit() {
      try {
        this.user.advisorUserId = this.$store.getters.$userLogged.id

        let req = await this.apiService.post("user/add", this.user);

        if (req.statusCode == 200) {
          this.$toast.success("Usuário criado com sucesso");

          setTimeout(() => {
            EventBus.$emit("reloadUsers", true);
            EventBus.$emit("closeModalOnCreateUser", true);
          }, 1500);
        }
      } catch (error) {
        const src = error && error.body && error.body.content;
        const msg = (src && 'message' in src ? src.message :
                    src && typeof src === 'string' ? src :
                    'Erro ao registrar usuário') || 'Erro ao registrar usuário';
        this.$toast.error(msg);
      }
    },
  },
  watch: {
    btnRegisterClicado(nv, ov) {
      if (nv == true) {
        this.submit();
      }
    },
  },
};
</script>

<style scoped>
.cdb-rdb {
  margin-top: 0 !important;
  width: 100%;
  padding-top: 0 !important;
  padding-bottom: 5rem;
}
.formulario {
  margin-top: 2rem;
  /* border: 1px solid yellow; */
  width: 100%;
}
.input {
  width: 100% !important;
}
.line {
  padding: 0 !important;
}
.column {
  padding-bottom: 0;
}
.content {
}
</style>
